import React from "react"
import { useSelector } from "react-redux"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PrivacyData } from "../queries/privacy"
const PrivacyPolicy = () => {
  const data = PrivacyData()

  const { headTitle, topSection, list } = data

  const { language } = useSelector(state => state.global)

  return (
    <Layout>
      <SEO title="Privacy-policy" pageId="cGFnZToyMzY3" />
      <div className="banner about_banner terms_of">
        <div className="container">
          <div className="banner_txt">
            <div className="w-100">
              <h1 className="text-center">{headTitle[language]}</h1>
            </div>
          </div>
        </div>
      </div>

      <section className="career_detail terms">
        <div className="container p-0">
          <div className="c_detail">
            <div className="c_detail_block w-100">
              <p>{topSection.text[language]}</p>
              <ul className="mb-0">
                {topSection.points.map(item => (
                  <li>{item.li[language]}</li>
                ))}
              </ul>
              {list.map(item => (
                <>
                  <h5>{item.title[language]}</h5>
                  <p>{item.text[language]}</p>
                </>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PrivacyPolicy



import { graphql, useStaticQuery } from 'gatsby'

export const PrivacyData = () => {
  const { wdwordpress: { page: { privacy_contents } } } = useStaticQuery(graphql`
    {
      wdwordpress {
        page(id: "cGFnZToyMzY3") {
          privacy_contents {
            headTitle {
              en
              sv
            }
            topSection {
              text {
                en
                sv
              }
              points {
                li {
                  en
                  sv
                }
              }
            }
            list {
              text {
                en
                sv
              }
              title {
                en
                sv
              }
            }
          }
        }
      }
    }
  `)
  return privacy_contents
}